[TextStyle-XB] {
  font-family: "Red-Hat-Extra-Bold";
}
[TextStyle-B] {
  font-family: "Red-Hat-Bold";
}
[TextStyle] {
  font-family: "Red-Hat-Regular";
}
[TextStyle-M] {
  font-family: "Red-Hat-Medium";
}
[TextStyle-L] {
  font-family: "Red-Hat-Light";
}
[TextStyle-Secondary] {
  font-family: "Secular";
}

[Title_22_32] {
  font-family: "Red-Hat-Regular";
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

[Body_14] {
  font-family: "Red-Hat-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18.52px;
  text-align: center;
}

[text-center] {
  text-align: center;
}

[text-left] {
  text-align: left;
}
