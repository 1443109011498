:root {
  --size-4: 0.25rem;
  --size-6: 0.375rem;
  --size-8: 0.5rem;
  --size-10: 0.625rem;
  --size-12: 0.75rem;
  --size-14: 0.875rem;
  --size-16: 1rem;
  --size-18: 1.125rem;
  --size-20: 1.25rem;
  --size-22: 1.375rem;
  --size-24: 1.5rem;
  --size-28: 1.75rem;
  --size-32: 2rem;
  --size-36: 2.25rem;
  --size-40: 2.5rem;
  --size-42: 2.625rem;
  --size-48: 3rem;
  --size-50: 3.125rem;
  --size-56: 3.5rem;
  --size-60: 3.75rem;
  --size-64: 4rem;
  --size-68: 4.25rem;
  --size-70: 4.375rem;
  --size-72: 4.5rem;
  --size-80: 5rem;
  --size-88: 5.5rem;
  --size-104: 6.5rem;
  --size-120: 7.5rem;
  --size-168: 10.5rem;
  --size-382: 23.875rem;
}

[pt-56] {
  padding-top: var(--size-56);
}
[pt-36] {
  padding-top: var(--size-36);
}
[pt-32] {
  padding-top: var(--size-32);
}
[pt-24] {
  padding-top: var(--size-24);
}
[pt-12] {
  padding-top: var(--size-12);
}
[pb-168] {
  padding-bottom: var(--size-168);
}
[pb-120] {
  padding-bottom: var(--size-120);
}
[pb-104] {
  padding-bottom: var(--size-104);
}
[pb-88] {
  padding-bottom: var(--size-88);
}
[pb-80] {
  padding-bottom: var(--size-80);
}
[pb-70] {
  padding-bottom: var(--size-70);
}
[pb-64] {
  padding-bottom: var(--size-64);
}
[pb-60] {
  padding-bottom: var(--size-60);
}
[pb-42] {
  padding-bottom: var(--size-42);
}
[pb-36] {
  padding-bottom: var(--size-36);
}
[pb-32] {
  padding-bottom: var(--size-32);
}
[pb-28] {
  padding-bottom: var(--size-28);
}
[pb-24] {
  padding-bottom: var(--size-24);
}
[pb-20] {
  padding-bottom: var(--size-20);
}
[pb-16] {
  padding-bottom: var(--size-16);
}
[pb-12] {
  padding-bottom: var(--size-12);
}
[pb-8] {
  padding-bottom: var(--size-8);
}
[pb-4] {
  padding-bottom: var(--size-4);
}

[px-24] {
  padding-left: var(--size-24);
  padding-right: var(--size-24);
}
[px-14] {
  padding: var(--size-14);
}
[py-4] {
  padding: var(--size-4) 0;
}
[py-8] {
  padding: var(--size-8) 0;
}

[m-0] {
  margin: var(--size-0);
}
[mb-0] {
  margin-bottom: var(--size-0);
}
[mb-24] {
  margin-bottom: var(--size-24);
}
[mb-20] {
  margin-bottom: var(--size-20);
}
[mb-16] {
  margin-bottom: var(--size-16);
}
[mb-12] {
  margin-bottom: var(--size-12);
}
[mb-8] {
  margin-bottom: var(--size-8);
}
[mb-4] {
  margin-bottom: var(--size-4);
}
