$font-family-base: "Red Hat Display", sans-serif;
$font-size-base: var(--size-16);
$font-weight-normal: 400;
$font-weight-bold: 700;

@font-face {
  font-family: Red-Hat-Extra-Bold;
  src: url("./../assets/fonts/redhat_extrabold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Red-Hat-Bold;
  src: url("./../assets/fonts/redhat_bold.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Red-Hat-Regular;
  src: url("./../assets/fonts/redhat_regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Red-Hat-Medium;
  src: url("./../assets/fonts/redhat_medium.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Red-Hat-Light;
  src: url("./../assets/fonts/redhat_light.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Red-Hat-Book;
  src: url("./../assets/fonts/redhat_book.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Secular;
  src: url("./../assets/fonts/secular.ttf");
  font-weight: 400;
  font-style: normal;
}

[Title_22_32] {
  font-size: var(--size-22);
  line-height: var(--size-32);
}
[Body_14] {
  font-size: var(--size-14);
  line-height: var(--size-18);
}

[fz-36] {
  font-size: var(--size-36);
}
[fz-32] {
  font-size: var(--size-32);
}
[fz-24] {
  font-size: var(--size-24);
}
[fz-22] {
  font-size: var(--size-22);
}
[fz-20] {
  font-size: var(--size-20);
}
[fz-16] {
  font-size: var(--size-16);
}
[fz-14] {
  font-size: var(--size-14);
}
[fz-12] {
  font-size: var(--size-12);
}
[fz-10] {
  font-size: var(--size-10);
}
[fz-8] {
  font-size: var(--size-8);
}
[fz-4] {
  font-size: var(--size-4);
}

[fw-400] {
  font-weight: 400;
}
[fw-500] {
  font-weight: 500;
}
[fw-700] {
  font-weight: 700;
}
