.p-dialog-mask {
  background-color: var(--phantom) !important;
}
.p-dialog {
  overflow: hidden !important;
  background-color: var(--ebony);
  border-radius: var(--size-16);
}

.dynamic-modal {
  width: 50%;
  max-width: 45rem;
  min-height: 15rem;
}
.dynamic-modal.start {
  animation: slideInRight 0.4s ease-in-out forwards !important;
}
.dynamic-modal.show {
  animation: slideInCenter 0.4s ease-in-out forwards !important;
}
.dynamic-modal.hide {
  animation: slideInLeft 0.4s ease-in-out forwards !important;
}
.dynamic-modal.closing {
  animation: slideOutRight 0.4s ease-in-out forwards !important;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideInCenter {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media (max-width: 37.5rem) {
  .p-dialog-mask {
    display: flex;
    align-items: flex-end;
  }
  .p-dialog {
    width: 100% !important;
  }
  .dynamic-modal {
    background-color: var(--ebony);
    border-radius: var(--size-16) var(--size-16) 0 0 !important;
  }
}
