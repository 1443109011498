@import "./fonts";

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  background-color: var(--ebony);
  margin: 0;
  padding: 0;
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--carbony);
}
.content {
  flex: 1;
  overflow-y: auto;
}
.container-pages {
  background-color: var(--ebony);
  border-radius: 1rem;
  overflow: hidden;
}
.content-pages {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: var(--ebony);
  padding: 0 var(--size-24);
  color: var(--milk);
  overflow: hidden;
}
@media (max-width: 37.5rem) {
  .container {
    height: auto;
    max-height: 100vh;
  }
  .content-pages {
    padding: 0 var(--size-16) !important;
  }
}

[w-100] {
  width: 100%;
}
[w-auto] {
  width: auto;
}
[h-100] {
  height: 100%;
}
[d-flex] {
  display: flex;
}
[flex-column] {
  flex-direction: column;
}
[cursor-pointer] {
  cursor: pointer;
}

p {
  font-family: "Red-Hat-Regular";
}
a {
  font-family: "Red-Hat-Regular";
  text-decoration: none;
  color: var(--milk);
}

[return-icon] {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--milk);
  cursor: pointer;
}

app-tcinput,
app-tcbutton,
app-select {
  width: 100%;
}

/* SCROLL MODERN */
/* Webkit */
::-webkit-scrollbar {
  width: 0.0625rem;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
