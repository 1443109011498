:root {
  --canary: #fce903;
  --racing: #ffbe00;
  --lightning: #ffd65e;
  --cloudy: #ededed;
  --milk: #ffffff;
  --carbon: #000000;
  --ebony: #131313;
  --carbony: linear-gradient(180deg, #000000 50%, #131313 100%);
  --ghost: #ffffff80;
  --concrete: #b1b1b1;
  --ash: #7d7d7d;
  --smoke: #3d3d3d;
  --error: #ff5f5f;
  --wine: #a3143b;
  --grass: #1c842d;
  --brick: #ccff1717;
  --water: #60bcff;
  --brick: #ff1717cc;
  --transparent: #00000000;
  --phantom: #000000b2;
}

[tcCanary] {
  color: var(--canary);
}
[tcRacing] {
  color: var(--racing);
}
[tcLightning] {
  color: var(--lightning);
}
[tcCarbon] {
  color: var(--carbon);
}
[tcEbony] {
  color: var(--ebony);
}
[tcCarbony] {
  color: var(--carbony);
}
[tcSmoke] {
  color: var(--smoke);
}
[tcAsh] {
  color: var(--ash);
}
[tcGhost] {
  color: var(--ghost);
}
[tcCloudy] {
  color: var(--cloudy);
}
[tcMilk] {
  color: var(--milk);
}
[tcConcrete] {
  color: var(--concrete);
}
[tcError] {
  color: var(--error);
}
[tcBrick] {
  color: var(--brick);
}
[tcGrass] {
  color: var(--grass);
}
[tcTransparent] {
  color: var(--transparent);
}
[tcWine] {
  color: var(--wine);
}
[tcWater] {
  color: var(--water);
}
