/* You can add global styles to this file, and also import other style files */
@import "./styles/global";
@import "./styles/fonts";
@import "./styles/colors";
@import "./styles/modals";
@import "./styles/spacing";
@import "./styles/textStyles";
@import "~primeicons/primeicons.css";

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300..700&display=swap");
